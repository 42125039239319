<template>
  <div class="event-container">
    <div class="event-container__content">
      <div class="event-container__header">
        <div
          :class="[
            'event-container__clock',
            { 'event-container__clock--is-live': matchState === 'LIVE' },
          ]"
        >
          {{ periodLabel }}
        </div>
        <div
          v-if="isLimitedOffer"
          class="limited-offer-indicator"
        >
          Limited offer
        </div>
      </div>
      <div
        :class="[
          'event-container__scoreboard',
          { 'event-container__scoreboard--is-usa-view': isUsaView },
        ]"
      >
        <div class="event-container__competitor">
          <div class="event-container__competitor-name">
            <BaseballClock
              v-if="isBaseball"
              side="HOME"
              :team-in-possession="teamInPossession"
            />
            <ShotClock
              v-else
              :icon="event.sportId"
              :is-active="isHomeInPossession"
            />
            {{ homeName }}
          </div>
          <div class="event-container__competitor-score">
            {{ homeScore }}
          </div>
        </div>
        <div class="event-container__competitor">
          <div class="event-container__competitor-name">
            <BaseballClock
              v-if="isBaseball"
              side="AWAY"
              :team-in-possession="teamInPossession"
            />
            <ShotClock
              v-else
              :icon="event.sportId"
              :is-active="isAwayInPossession"
            />
            {{ awayName }}
          </div>
          <div class="event-container__competitor-score">
            {{ awayScore }}
          </div>
        </div>
      </div>
      <div
        v-if="isBaseball || isFootball"
        class="event-container__baseball-scoreboard-separator"
      />
      <BaseballIndicators
        v-if="isBaseball"
        :balls="balls"
        :strikes="strikes"
        :outs="outs"
        :base1="base1"
        :base2="base2"
        :base3="base3"
      />
      <div
        v-if="isFootball"
        class="scoreboard__football-meta"
      >
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            Down:
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballDownValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            To go:
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballToGoValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            On:
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballOnValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            Play running:
          </div>
          <div class="scoreboard__football-meta-value">
            <BooleanIndicator
              v-if="footballPlayValueAvailable"
              :value="footballPlayValue"
            />
            <span v-else>
              -
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="isBaseball"
        class="event-container__baseball-scoreboard"
      >
        <div class="event-container__baseball-scoreboard-group">
          <div class="event-container__baseball-scoreboard-label">
            <Icon name="pitcher" />
            Pitcher:
          </div>
          <div
            class="event-container__baseball-scoreboard-value"
            :title="pitcherName"
          >
            {{ pitcherName }}
          </div>
        </div>
        <div class="event-container__baseball-scoreboard-group">
          <div class="event-container__baseball-scoreboard-label">
            P. count:
          </div>
          <div class="event-container__baseball-scoreboard-value">
            {{ pitchCount }}
          </div>
        </div>
        <div class="event-container__baseball-scoreboard-group">
          <div class="event-container__baseball-scoreboard-label">
            Batting ord:
          </div>
          <div class="event-container__baseball-scoreboard-value">
            {{ timesThrough }}
          </div>
        </div>
        <div class="event-container__baseball-scoreboard-group">
          <div class="event-container__baseball-scoreboard-label">
            <Icon name="hitter" />
            Hitter:
          </div>
          <div
            class="event-container__baseball-scoreboard-value"
            :title="hitterName"
          >
            {{ hitterName }}
          </div>
        </div>
      </div>
      <MarketTiersTableSlim
        :event-id="eventId"
        :match-state="matchState"
        :limit-configuration="limitConfiguration"
        :in-prematch="inPrematch"
        :in-break="inBreak"
        @update:limit-configuration="(limitConfiguration) => updateLimitConfiguration(eventId, limitConfiguration)"
      />
    </div>
    <div class="event-container__footer">
      <div class="event-container__actions">
        <button
          :class="[
            'event-container__actions-button',
            { 'event-container__actions-button--is-suspended': isSuspended },
            { 'event-container__actions-button--is-disabled': isSuspensionPending },
          ]"
          @click="toggleSuspension"
        >
          <Spinner
            v-if="isSuspensionPending"
            small
          />
          <Icon
            v-else
            :name="suspendButtonIcon"
          />
          {{ suspendButtonLabel }}
        </button>
        <ActionButton
          icon="info"
          title="Event info"
          tooltip-top
          @click="openDrawer(eventId, 'info')"
        />
        <ActionButton
          icon="bar-chart"
          title="Statistics"
          tooltip-top
          @click="openDrawer(eventId, 'stats')"
        />
        <ActionButton
          icon="list"
          title="Markets"
          tooltip-top
          @click="openDrawer(eventId, 'markets')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import {
  replace,
  includes,
  find,
  isNil,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { useSquad } from '@/composables';
import sportIds from '@/services/helpers/sports';
import Event from '@/services/helpers/event';
import { parseEventModel, getSportUtils } from '@/services/helpers/event-drawer';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import ActionButton from '@/components/trading-ui/common/ActionButton';
import BaseballIndicators from '@/components/common/BaseballIndicators';
import BooleanIndicator from '@/components/common/BooleanIndicator';
import MarketTiersTableSlim from './MarketTiersTableSlim';
import BaseballClock from '@/components/common/BaseballClock';
import ShotClock from '@/components/common/ShotClock';

const { BASEBALL_ID, FOOTBALL_ID } = sportIds;

export default {
  components: {
    Icon,
    Spinner,
    ActionButton,
    BaseballIndicators,
    BooleanIndicator,
    MarketTiersTableSlim,
    BaseballClock,
    ShotClock,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const event = computed(() => parseEventModel(props.event));
    const isLimitedOffer = computed(() => props.event?.params?.selectedMarkets?.limitedOffer || false);
    const eventId = computed(() => event.value.id);
    const sportId = computed(() => event.value.sport.id);
    const startsAt = computed(() => event.value.startsAt);
    const matchState = computed(() => event.value.matchState);
    const sportUtils = computed(() => getSportUtils(sportId.value));
    const live = computed(() => Event.isLive(event.value) && event.value.period !== 'EVENT_COMPLETED');
    const livePeriods = computed(() => sportUtils.value?.getLivePeriods(event.value) || []);
    const periodLabel = computed(() => {
      if (matchState.value === 'CANCELED') return 'Canceled';
      if (matchState.value === 'FINISHED') return 'Finished';
      if (live.value && !includes(livePeriods.value, event.value.period)) return 'Live';
      if (matchState.value === 'LIVE') {
        return sportUtils.value.formatPeriod(event.value, {
          formatLabel: sportUtils.value.formatPeriodAbbreviation,
          formatTime: sportUtils.value.formatPeriodTime,
          separator: '•',
        });
      }
      const formattedStartsAt = format(zonedTimeToUtc(startsAt.value, 'UTC'), 'MMM d, yyyy · hh:mm a');
      const currentYearSuffix = `, ${new Date().getFullYear()}`;
      return replace(formattedStartsAt, currentYearSuffix, '');
    });
    const homeName = computed(() => event.value.home.name);
    const homeScore = computed(() => event.value.home.score);
    const awayName = computed(() => event.value.away.name);
    const awayScore = computed(() => event.value.away.score);
    const isUsaView = computed(() => event.value.isUSAView);

    const isSuspensionPending = ref(false);
    const isSuspended = computed(() => event.value.isSuspended);
    const suspendButtonIcon = computed(() => (isSuspended.value ? 'play' : 'pause'));
    const suspendButtonLabel = computed(() => (isSuspended.value ? 'Unsuspend' : 'Suspend'));

    const toggleSuspension = () => {
      if (isSuspended.value) {
        store.dispatch('unsuspendMultiviewEventListEvent', eventId.value);
      } else {
        store.dispatch('suspendMultiviewEventListEvent', eventId.value);
      }
    };
    const openDrawer = inject('openDrawer');

    const limitConfiguration = computed(() => event.value.limitConfiguration);
    const inPrematch = computed(() => event.value.inPrematch);
    const inBreak = computed(() => event.value.inBreak);
    const updateLimitConfiguration = (id, newLimitConfiguration) => {
      store.dispatch('setMultiviewEventListEventLimits', {
        ...newLimitConfiguration,
        eventId: id,
      });
    };

    const isBaseball = computed(() => props.event?.sportId === BASEBALL_ID);
    const balls = computed(() => event.value?.balls);
    const strikes = computed(() => event.value?.strikes);
    const outs = computed(() => event.value?.outs);
    const base1 = computed(() => event.value?.base1);
    const base2 = computed(() => event.value?.base2);
    const base3 = computed(() => event.value?.base3);
    const { squad } = useSquad(event.value);
    const pitcherName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => event.value?.currentPitcher?.index === playerIndex,
      );
      return player?.shortName || '-';
    });
    const pitchCount = computed(() => {
      const value = event.value?.currentPitcher?.stats?.pitchCount;
      return isNil(value) ? '-' : value;
    });
    const timesThrough = computed(() => {
      const value = event.value?.currentPitcher?.stats?.timesThrough;
      return isNil(value) ? '-' : value;
    });
    const hitterName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => event.value?.currentHitter?.index === playerIndex,
      );
      return player?.shortName || '-';
    });

    const isFootball = computed(() => event.value?.sportId === FOOTBALL_ID);
    const footballDownValue = computed(() => (isNil(event.value.downNumber) ? '-' : event.value.downNumber));
    const footballToGoValue = computed(() => (isNil(event.value.yardsToWinDown) ? '-' : event.value.yardsToWinDown));
    const footballOnValue = computed(() => (isNil(event.value.yardsToEndZone) ? '-' : event.value.yardsToEndZone));
    const footballPlayValueAvailable = computed(() => !isNil(event.value.isPlayRunning));
    const footballPlayValue = computed(() => !!event.value.isPlayRunning);
    const teamInPossession = computed(() => (event.value.matchState === 'LIVE' ? (event.value.teamInPossession || '') : ''));
    const isAwayInPossession = computed(() => teamInPossession.value === 'AWAY');
    const isHomeInPossession = computed(() => teamInPossession.value === 'HOME');

    return {
      eventId,
      matchState,
      periodLabel,
      homeName,
      homeScore,
      awayName,
      awayScore,
      isUsaView,
      isSuspensionPending,
      isSuspended,
      suspendButtonIcon,
      suspendButtonLabel,
      toggleSuspension,
      openDrawer,
      limitConfiguration,
      inPrematch,
      inBreak,
      updateLimitConfiguration,
      isBaseball,
      balls,
      strikes,
      outs,
      base1,
      base2,
      base3,
      pitcherName,
      pitchCount,
      timesThrough,
      hitterName,
      isFootball,
      footballDownValue,
      footballToGoValue,
      footballOnValue,
      footballPlayValueAvailable,
      footballPlayValue,
      teamInPossession,
      isAwayInPossession,
      isHomeInPossession,
      isLimitedOffer,
    };
  },
};
</script>

<style lang="scss">
.event-container {
  flex: 1;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
  }

  &__footer {
    border-top: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__clock {
      color: #6D6D6D;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 4px 8px;

      &--is-live {
        color: #00BC57;
      }
    }
    .limited-offer-indicator {
      display: inline-block;
      font-size: 10px;
      padding: 2px;
      border-radius: 2px;
      background-color: #E8EFFF;
      color: #0E3999;
    }
  }

  &__scoreboard {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 8px;

    &--is-usa-view {
      flex-direction: column-reverse;
    }
  }

  &__competitor {
    flex: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-score {
      font-weight: bold;
    }
    &-name {
      display: flex;
      align-items: center;
      gap: 0 8px;
    }
  }

  &__actions {
    display: flex;
    width: 100%;

    &-button {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: 100%;
      padding: 12px;
      cursor: pointer;
      user-select: none;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      color: #FF2E2D;
      fill: #FF2E2D;
      stroke: rgba(0, 0, 0, 0);
      border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);

      &--is-suspended {
        color: #191414;
        fill: rgba(0, 0, 0, 0);
        stroke: #191414;
      }

      &--is-disabled {
        opacity: 0.5;
        cursor: progress;
      }

      .icon {
        width: 16px;
        height: 16px;
        fill: inherit;

        svg,
        svg path {
          fill: inherit;
          stroke: inherit;
          stroke-width: 2;
        }
      }
    }

    .multiview-action-button {
      border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
      border-radius: 0;

      svg path {
        stroke: #191414;
        opacity: 1;
      }
    }

    .tooltip:last-of-type {
      .multiview-action-button {
        border-right: none;
      }
    }
  }

  .baseball-indicators {
    padding: 0 8px;
    justify-content: flex-start;
  }

  &__baseball-scoreboard {
    display: grid;
    grid-template-columns: 95px 41px 55px 95px;
    column-gap: 8px;
    padding: 0 8px;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-label {
      display: flex;
      align-items: center;
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 11.85px;
      gap: 4px;

      .icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
      }
    }

    &-value {
      color: #191414;
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.22px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-separator {
      background-color: #F0F0F0;
      min-height: 1px;
      height: 1px;
      max-height: 1px;
    }
  }

  .scoreboard__football-meta {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 23.24px;
    width: 100%;
    gap: 32px;
    padding-bottom: 20px;
    padding: 5px 8px 0;

    &-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2px;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 400;
      line-height: 11.85px;
      color: #6D6D6D;
      white-space: nowrap;

      .icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
      }
    }

    &-value {
      color: #191414;
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.22px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
